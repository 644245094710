.buttonCancel {
  display: inline-block;
  background-color: #00948b;
  color: whitesmoke;
  border-radius: 30px;
  box-shadow: 0 3px #999;
  padding: 5px 15px 5px 15px;
  font-size: 12px;
}

.buttonCancel:hover {
  background-color: #03635c;
  color: whitesmoke;
  box-shadow: 0 3px #999;
}

.buttonCancel:active {
  background-color: #03635c;
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}

.buttonSave {
  display: inline-block;
  background-color: #00948b;
  color: whitesmoke;
  border-radius: 30px;
  box-shadow: 0 3px #999;
  padding: 5px 22px 5px 22px;
  font-size: 12px;
}

.buttonSave:hover {
  background-color: #03635c;
  color: whitesmoke;
  box-shadow: 0 3px #999;
}

.buttonSave:active {
  background-color: #03635c;
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}
