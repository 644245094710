body {
  margin: 0;
  font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-primary {
  background-color: #4fa2b4 !important;
  border-color: #4fa2b4;
}
.btn-primary:hover {
  background-color: #4fa2b4;
  border-color: #4fa2b4;
}

.checkboxBorder {
  border: 1px;
}

.lisColor1 {
  background-color: #e1ebf0;
}

.nav-item {
  cursor: pointer;
}

/* .lisColor2 {
  background-color: #00948B;
} */

.card .table td,
.card .table th {
  padding-left: 1rem;
  padding-right: 1rem;
}

.lisColor2 {
  background-color: #066962;
}

.MuiListItem-root.Mui-selected {
  background-color: #e1ebf0 !important;
}

/* removes the padding from list elements in Mui ListItem */
.MuiListItem-gutters {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* this decreases the padding around icons in material table */
.MuiIconButton-root {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

@font-face {
  font-family: "Roboto Condensed";
  src: local("Roboto Condensed"),
    url(./assets2/fonts/fonts/roboto-condensed-v18-latin/roboto-condensed-v18-latin-regular.ttf)
      format("truetype");
}

/* this aligns the title in material table with the header */
.MuiToolbar-root.MuiToolbar-regular {
  padding-left: 16px;
}

.MuiTypography-body1 {
  font-family: "Roboto Condensed" !important;
}

/* this adds the color to all of the table headings */
.MuiTypography-root.MuiTypography-h6 {
  color: #00948b;
}

/* fixed width button */
.fixedWidthButton {
  width: 78px;
}

button.MuiButtonBase-root.MuiIconButton-root {
  padding-right: 0px;
  padding-left: 0px;
}

.modal-title {
  color: #066962;
  text-align: center;
  font-size: 1rem;
}

/* bootstrap's small font size */
.small-font-size {
  font-size: 0.8rem;
}

.normal-font-size {
  font-size: 1rem;
}

.link-pointer {
  cursor: pointer;
}

.underlined {
  text-decoration: underline;
}

.text-lisColor2 {
  color: #066962;
}

.text-lisColor1 {
  color: #00948b;
}

.nav-link-sidebar {
  background-color: #066962;
}

.logo-image {
  display: block;
  width: 100%;
  height: auto;
}

.top-right {
  top: 0;
  right: 0;
}

.list-group-custom .list-group-item:hover {
  background-color: #066962;
}

#dropdownMenuButtonUserName {
  outline: none;
  border-style: none;
  box-shadow: none;
}

/* This will not wrap the contents inside the material table cells */
/* This also screws every one of the detail panel views of material table */
/* .MuiTableCell-root-nowrapCell {
  white-space: nowrap;
} */

/* .list-group-item-action {
  width: 100%;
  color: #525f7f;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #525f7f;
    text-decoration: none;
    background-color: #82e252; }
  .list-group-item-action:active {
    color: #525f7f;
    background-color: #168aff; } */

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.sidenav a,
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.MuiIconButton-label {
  height: 24px !important;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

/* y-axis ticks are clipped in BarGraph of recharts */
/* to avoid this make the overflow in recharts surface visible */
.recharts-surface {
  overflow: visible;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: center !important;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  color: #066962 !important;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center !important;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #066962 !important;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.viewFlex {
  display: flex;
  flex-direction: row;
}

.viewFlexWithMargin {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: center;
}

.g {
  background-color: #00ff00;
  width: 30px;
}

.r {
  background-color: #ff0000;
  width: 30px;
}

.a {
  background-color: #ffbf00;
  width: 30px;
}

select option {
  color: #000;
}
:disabled {
  background-color: #eee;
  color: #777;
}
