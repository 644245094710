.ipp-page {
  padding: 1rem 0;
  font-size: 14px;
  margin-bottom: 1.5rem;

  .container-fluid {
    margin: 12px 0px;
  }

  .card-header {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #088b8b;

    .card-title {
      color: #fff;
      margin-bottom: 0;
    }

    .card-tools {
      .btn.btn-tool {
        padding: 0.3rem 0.3rem;
      }
    }
  }

  .card-body {
    max-height: 60vh;
    overflow: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: inherit;
    -webkit-transition: height 1.5s;
    transition: height 1.5s;
    -moz-transition: height 1.5s;
    -ms-transition: height 1.5s;
    -o-transition: height 1.5s;

    select option {
      color: #000;
    }
    :disabled {
      background-color: #eee;
      color: #777;
    }

    .spinner-border {
      color: #00948b;
      align-self: center;
      margin: 8px;
    }
    .form-group {
      .label-with-tool {
        display: flex;
        justify-content: space-between;

        i {
          background-color: #00948b;
          color: #fff;
          padding: 0.25rem;
          align-self: center;
          border-radius: 0.4rem;
          cursor: pointer;
        }
      }

      .field-with-tool {
        display: flex;
        justify-content: space-between;

        i {
          background-color: red;
          color: #fff;
          padding: 0.25rem;
          align-self: center;
          border-radius: 0.4rem;
          margin-left: 0.5rem;
          cursor: pointer;
        }
      }
    }

    .form-preview {
      display: flex;
      flex-direction: column;
      .form-dynamic-item {
        margin: 0.25rem 0;
        background-color: #eee;
        padding: 0.25rem 0.5rem;
        border-radius: 0.5rem;
      }

      .dynamic-preview-block {
        margin-top: 0.5rem;
        padding-right: 1rem;
        padding-left: 1rem;

        .ipp-preview-row {
          // background-color: #eee;
          //border-radius: 0.5rem;
          border-bottom: 1px solid #eee;
        }
      }

      .ipp-grid-preview-title-bar {
        background-color: #e1ebf0;
        width: 100%;
        position: sticky;
        align-self: center;
        margin: 0;
        z-index: 10;
        padding-left: 1rem;
        padding-right: 1rem;
        padding: 8px;
      }
    }

    .block-tool {
      display: flex;
      justify-content: flex-end;

      i {
        background-color: red;
        color: #fff;
        padding: 0.25rem;
        align-self: center;
        border-radius: 0.4rem;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }

    .dynamic-form-block {
      margin-top: 0.5rem;
      padding: 1rem;
      border-bottom: 1px solid #eee;
      width: 100%;

      .dynamic-field-block {
        border: 1px solid #eee;
        border-radius: 0.5rem;
      }

      .form-group {
        margin-bottom: 0;
        justify-content: center;
        align-items: center;
        text-align: center;

        .field-with-tool {
          justify-content: end;
        }
      }
    }

    .ipp-grid-title-bar {
      background-color: #e1ebf0;
      width: 100%;
      position: -webkit - sticky; /* for Safari */
      position: sticky;
      top: 0;
      align-self: center;
      margin: 0;
      z-index: 10;
      padding: 8px;
      //text-align: center;
      align-items: center;

      .form-block-right-tool {
        // align-self: center;
        justify-content: flex-end;
        text-align: end;
        flex-grow: 0;
        i {
          background-color: #00948b;
          color: #fff;
          padding: 0.25rem;
          align-self: center;
          border-radius: 50%;
          cursor: pointer;
          justify-content: center;
        }
      }

      .ipp-grid-labels {
        padding: 0;
      }
    }
  }

  .card-body::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  .card-body::-webkit-scrollbar-track {
    background: #ddd; /* color of the tracking area */
  }

  .card-body::-webkit-scrollbar-thumb {
    background-color: #aaa; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #ddd; /* creates padding around scroll thumb */
  }
}
